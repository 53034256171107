<template>
  <div>
    <Baner />
    <Newera/>
    <Ubrand/>
    <Towe/>
  </div>
</template>

<script>
import Baner from "@/components/pagespers/program/brand/Baner.vue";
import Newera from "@/components/pagespers/program/brand/Newera.vue";
import Ubrand from "@/components/pagespers/program/brand/Ubrand.vue";
import Towe from "@/components/pagespers/program/brand/Towe.vue";

export default {
  components: {
    Baner,
    Newera,
    Ubrand,
    Towe
  },

  data() {
    return {};
  },
};
</script>

<style scoped lang="less"></style>
