<template>
  <div class="ubrand">
    <div class="ubrand_box">
      <div class="ubrand_top">
        <p>独家品牌</p>
        <p>EXCLUSIVE BRAND</p>
      </div>
      <div class="ubrand_botton">
        <div class="content_left">
          <p>我们所支持的</p>
          <p>We supported it</p>
          <p>
            公司创建以来合作海内外的供应商众多
            实时供应品牌，公司团队成熟，有世界
            各地的人才涌入公司，公司有孵化的艺
            人及模特，媒体团队从创立以来一直带
            领公司走向世界，孵化众多知名企业， 代言宣传模式稳固。
          </p>
          <button>点击了解</button>
        </div>
        <div class="content_right">
          <img src="@/assets/imgs/brand/pexe.png" alt="" />
        </div>
      </div>
      <div class="text_p">
        <p>2018-至今<br />已孵化企业</p>
        <p>2620+</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
};
</script>
<style scoped lang="less">
.ubrand {
  width: 100%;
  height: 800px;
  .ubrand_box {
    max-width: 1200px;
    margin: 0 auto;
    .ubrand_top {
      text-align: center;
      p:nth-child(1) {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      p:nth-child(2) {
        font-size: 19px;
        margin-top: 25px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }
    .ubrand_botton {
      max-width: 1200px;
      height: 550px;
      margin-top: 12px;
      background: #f0f0f0;
      .content_left {
        width: 440px;
        margin: 100px 0 0 100px;
        float: left;
        p:nth-child(1) {
          font-size: 27px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          margin-bottom: 15px;
        }
        p:nth-child(2) {
          font-size: 27px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          margin-bottom: 20px;
        }
        p:nth-child(3) {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #626262;
          line-height: 36px;
        }
        button {
          width: 130px;
          height: 35px;
          border: 1px solid #333333;
          border-radius: 5px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin-top: 80px;
          outline: none;
        }
      }
      .content_right {
        float: right;
        margin-top: 130px;
        img {
          width: 600px;
          height: 500px;
          margin-right: -70px;
        }
      }
    }
    .text_p {
      width: 280px;
      margin-left: 100px;
      p:nth-child(1) {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        float: left;
        margin-top: 36px;
      }
      p:nth-child(2) {
        font-size: 50px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        float: right;
        margin-top: 33px;
      }
    }
  }
}
</style>
