<template>
  <div class="newera">
    <div class="topl">
      <i class="i"></i>
      <i class="i"></i>
      <p>
        我们的SAAS平台将帮助每个人进入品牌新世代，使我们更接近品牌前沿新战线。
      </p>
    </div>
    <div class="newera_box">
      <div class="content_left">
        <img src="@/assets/imgs/brand/ppxsd.png" alt="" />
      </div>
      <div class="content_right">
        <p>品牌新时代帷幕已经<br />揭开</p>
        <p>
          我们旗下拥有:网红孵化、电商孵化、品推
          、微站、广告助手等解决方案，帮助营效
          率和盈利能力。昆仑虚精准营销业营销自
          动化等技术及优质媒体源，为广投放服务 。为商户提供更多。
        </p>
        <button>点击了解</button>
      </div>
    </div>

    <div class="bares">
      <div class="bares_content">
        <div class="content_top">
          <p>孵化定位我们能</p>
        </div>
        <div class="content_botton">
          <div class="botton_inedx" v-for="(item, index) in content" :key="index">
            <p>{{item.text}}</p>
            <p>
             {{item.texti}}
            </p>
            <i class="tent_i"></i>
          </div>
      
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: [
        {
          text: "形象设计",
          texti:
            "对客户产品进行市场定位分析，针对客户的要求以及市场调研用户的需求，对企业形象进行设计及包装。",
        },
        {
          text: "营销策划",
          texti:
            "产品竞争力策划，即产品核心卖点提炼与产品组合规划，提炼产品优势并整合消费者最关注的核心卖点。",
        },
        {
          text: "背景策划",
          texti:
            "了解品牌方向，分析用户需求以及当地特色 进行背景故事的编写。包括视频广告创意、文案撰写、推广工具文案撰写等。",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.newera {
  width: 100%;
  .topl {
    width: 100%;
    margin-bottom: 90px;
    margin-top: 80px;
    text-align: center;
    p {
      font-size: 20px;
      font-family: unset;
      font-weight: 500;
      line-height: 25px;
      color: #333333;
    }
    .i::after {
      content: "";
      display: inline-block;
      width: 159px;
      height: 2px;
      background: #333333;
    }
    .i:nth-child(1) {
      float: left;
    }
    .i:nth-child(2) {
      float: right;
    }
  }
  .newera_box {
    max-width: 1200px;
    height: 370px;
    margin: 0 auto;
    .content_left {
      width: 610px;
      float: left;
      img {
        width: 610px;
      }
    }
    .content_right {
      width: 455px;
      float: right;
      p:nth-child(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
        line-height: 35px;
      }
      p:nth-child(2) {
        font-size: 19px;
        width: 360px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #444444;
        line-height: 32px;
      }
      button {
        width: 120px;
        height: 30px;
        float: right;
        border: 1px solid #333333;
        border-radius: 5px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-right: 115px;
        margin-top: 20px;
        outline: none;
      }
    }
  }
  .bares {
    width: 100%;
    height: 500px;
    margin-bottom: 100px;
    overflow: hidden;
    background: url("../../../../assets/imgs/brand/fhdwwm.png") center no-repeat;
    .bares_content {
      max-width: 1200px;
      margin: 0 auto;
      height: 300px;
      .content_top {
        margin-top: 80px;
        p {
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          letter-spacing: 1px;
          color: #ffffff;
        }
      }
      .content_botton {
        text-align: center;
        .botton_inedx {
          margin: 0 auto;
          width: 324px;
          p:nth-child(1) {
            font-size: 27px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #fefefe;
            line-height: 106px;
          }
          p:nth-child(2) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            color: #dadada;
            line-height: 25px;
          }
          .tent_i {
            display: inline-block;
            width: 134px;
            height: 2px;
            background: #ffffff;
            margin-top: 50px;
          }
        }
        .botton_inedx:nth-child(1) {
          float: left;
        }
        .botton_inedx:nth-child(2) {
          float: right;
        }
      }
    }
  }
}
</style>
