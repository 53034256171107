<template>
  <div class="towe">
    <div class="towe_box">
      <div class="content_top">
        <p>了解我们</p>
        <p>TO UNDERSTANDING</p>
      </div>
      <div class="content_button">
        <img src="@/assets/imgs/brand/hljwm.png" alt="" />
        <p>更多品牌的选择</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped lang="less">
.towe {
  width: 100%;
  .towe_box {
    max-width: 1200px;
    margin: 0 auto;
    .content_top {
      text-align: center;
      p:nth-child(1) {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      p:nth-child(2) {
        font-size: 19px;
        margin-top: 25px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-bottom: 90px;
        color: #333333;
      }
    }
    .content_button {
      text-align: center;
      p {
        width: 296px;
        margin: 0 auto;
        margin-top: 90px;
        padding-top: 15px;
        border-top: 2px solid #666666;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        margin-bottom: 80px;
        color: #666666;
      }
    }
  }
}
</style>
